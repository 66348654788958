import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await sendRequest();
      console.log("response",response)
      setMessage(response.message); // Wyświetla komunikat zwrotny od serwera
    } catch (error) {
      setMessage(error.message); // Wyświetla komunikat o błędzie
    }
  };

  const sendArticle = async (url) => {
    try {
      const response = await axios.post('http://94.130.178.184:3000/articles', { url });
      console.log('Serwer odpowiedział:', response.data);
      return response.data; // Zwraca dane, np. wiadomość o sukcesie
    } catch (error) {
      console.error('Błąd podczas wysyłania danych:', error.response?.data || error.message);
      throw new Error(error.response?.data?.message || 'Wystąpił błąd podczas przetwarzania żądania');
    }
  };

  const sendRequest = async () => {
    try {
      const response = await axios.post('http://94.130.178.184:3000/test');
      console.log('Odpowiedź serwera:', response.data);
    } catch (error) {
      console.error('Błąd:', error);
    }
  };

  return (
    <div className="App">
      <input
        type="text"
        placeholder="Wpisz URL artykułu"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <button onClick={handleSubmit}>Wyślij artykuł</button>
      {message && <p>{message}</p>}
    </div>
  );
}

export default App;
